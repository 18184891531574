// import & init vxe-pc-ui plugins

import type { App } from 'vue'

import VXEUI from 'vxe-pc-ui'
import 'vxe-pc-ui/lib/style.css'

// 插件可以是一个带 install() 方法的对象，亦或直接是一个将被用作 install() 方法的函数。(https://cn.vuejs.org/api/application#app-use)
function install(app: App) {
  app.use(VXEUI)
}

export default { install }

